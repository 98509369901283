<template>
  <div class="bgLayer" style="background:#111;">
    <home-nav></home-nav>
    <main class="main">
      <slot></slot>
    </main>
  <home-footer></home-footer>
</div></template>

<script>
import HomeFooter from './homeFooter.vue';
import HomeNav from './homeNav.vue';
export default {
  components: {
    HomeFooter, HomeNav
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 960px;
  padding: 100px 0;
  margin: 0 auto;


  color: #f5f5f5;

  h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size:24px;
  }

  h2 {
    font-size:20px;
    margin-bottom: 1.2em;
  }

  h3 {
    margin-bottom: 1em;
  }

  section {
    padding: 0 64px;
    margin-bottom: 40px;
  }

  p {
    text-indent: 2em;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 0.75em;
  }
}</style>
<template>
  <single-page>
    <div class="breadcrum"><router-link to="/">{{ $t('Home')}}</router-link> / <span>{{ $t('blog') }}</span></div>
    <div class="item-list">
      <div class="item">
          <h3 class="item-title"><router-link to="/BNB-Chain-Perpetual-Platform-MEKE-to-Make-Foray-into-Cryptocurrency-Derivative-Market">BNB Chain's Perpetual Platform MEKE to Make Foray into Cryptocurrency Derivative Market</router-link></h3>
          <p class="item-desc">The entire cryptocurrency market witnessed waning popularity over the past two years due to the deteriorating global economic environment and adverse events within the cryptocurrency industry. Nevertheless, the market continues to exhibit exuberance when it comes to daily trading.</p>
          <div class="extra">
            <span class="author">Source: MK Digiworld</span>
            <span class="updated_at">Published: Jun 19, 2023 at 8:20 p.m. ET</span>
          </div>
        </div>

      <div class="item">
        <h3 class="item-title"><router-link to="/MEKE-a-rising-star-in-cryptocurrency-derivatives-trading">MEKE, a rising star in cryptocurrency derivatives trading</router-link></h3>
        <p class="item-desc">While the masses are engrossed in MEME tokens and BRC20, a dedicated assembly of tech aficionados, passionate
            about the cryptocurrency industry, remains steadfast in their pursuit of the ideal that "technology changes the
            world”. MEKE, a decentralized perpetual contract trading platform developed on Binance Smart Chain, is being
            crafted to fruition by a cohort of technologically zealous individuals from the United States.</p>
        <div class="extra">
          <span class="author">Source: MK Digiworld</span>
          <span class="updated_at">Published: May 28, 2023 at 7:17 p.m. ET</span>
        </div>
      </div>
    </div>
  </single-page>
</template>

<script>
import SinglePage from './components/singlePage'
export default {
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped>
  .breadcrum {
    padding: 24px 0;
    font-size:18px;
    & > a, & > span {
      padding: 0 0.5em;
    }
  }
  .item-list {
    & > .item {
      border-top: 1px dashed #3f3f3f;
      padding:24px 0;

      & > .item-title {
        & > a {
          color:unset;
        }
        margin-bottom:8px;
      }

      & > .item-desc {
        text-indent: 0;
        opacity:0.85;
        font-size: 16px;
        margin-bottom:8px;
      }

      & > .extra {
        display:block;
        opacity:0.65;
        font-size:12px;

        & > span {
          margin-right: 2em;
        }
      }
    }
    & > .item:first {
      border:none;
    }
  }

</style>